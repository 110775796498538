import React from 'react'
import Layout from '../components/Layout'

const PageNotFound = () => {
  return (
    <Layout>
      <div>Sorry, we could not find what you were looking for. 😞</div>
    </Layout>
  )
}

export default PageNotFound